import {sortCaret} from "../../_metronic/_helpers";
import {CreatedAtColumnFormatter, EnabledColumnFormatter, UpdatedAtColumnFormatter} from "./column-formatters";
import React from "react";

export const api_url = process.env.REACT_APP_API_URL
export const api_format = "YYYY-MM-DD HH:mm:ss"
export const upload_path = api_url+"/uploads";
export const languages = ["ar","fr","en"]
export const preDefinedNutrition  = {
  carb: {ar:"كربوهيدرات", en:"Carb", fr:"Carb"},
  fat: {ar:"دهون", en:"Fats", fr:"Graisses"},
  protein: {ar:"بروتينات", en:"Proteins", fr:"Protéines"},
};
export const preDefineIngredients  = {
  _1: {ar:"سكر", en:"Sugar", fr:"Sucre"},
  _2: {ar:"بيض", en:"Egg", fr:"Oeuf"},
  _3: {ar:"طحين", en:"Flour", fr:"Farine "},
  _4: {ar:"حليب", en:"Milk", fr:"Lait "},
  _5: {ar:"ملح", en:"Salt", fr:"Sel"},
};

export const enabledMap = {
  true:"Disabled",
  false:"Enabled",
}


export const getFormData = (entity)=>{
  const formData = new FormData();
  if(entity._file)
  {
    formData.append('_file', entity._file);
    delete entity._file;
  }
  formData.append("object", JSON.stringify(entity));
  return formData;
}

export const axiosFormDataConfigs = {
  headers : {
    'accept': 'application/json',
    'Content-Type': `multipart/form-data;`,
  }
}

export const TABLE_COLUMNS = {
  enabled: {
    dataField: "enabled",
    text: "Status",
    sort: true,
    sortCaret: sortCaret,
    formatter: EnabledColumnFormatter,
  },
  created_at:{
    dataField: "created_at",
    text: "Créé le",
    sort: true,
    sortCaret: sortCaret,
    formatter: CreatedAtColumnFormatter,
  },
  updated_at:{
    dataField: "updated_at",
    text: "Dernére mise à jour",
    sort: true,
    sortCaret: sortCaret,
    formatter: UpdatedAtColumnFormatter,
  },
  file_name:(UPLOAD_PATH)=>{
    return {
        dataField: "file_name",
            text: "Cover",
          sort: true,
          sortCaret: sortCaret,
          formatter: (cellContent, row) => {
            let url = `${UPLOAD_PATH}/default.png`;
            if(row.file_name) url = `${UPLOAD_PATH}/${row.id}/${row.file_name}`;
            return (
                <div className="symbol symbol-70 symbol-2by3 mr-3">
                  <div className="symbol-label" style={{backgroundImage: "url("+url+")"}}/>
                </div>
            )
          },
      }
    }
}
