import {upload_path} from "../../_AE/Helpers";

export const ROUTE = {
  prefix: "/users"
}
export const context_title = {
  singular: "User",
  plural: "Users",
}
export const images_path = upload_path+"/users"


export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    // enabled: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "created_at",
  pageNumber: 1,
  pageSize: 10
};

export const defaultEntity = {
  id: undefined,
  title_fr: "",
  title_en: "",
  title_ar: "",
  advantages: [],
  doses: [],

  enabled: true,
};