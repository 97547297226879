import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getFieldCSSClasses2 = function(errors, touched, fieldName) {//todo touched
  const defaultClasses = "form-control";
  if(!fieldName) return defaultClasses;

  if(errors)
  {
    let a = fieldName.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '').split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      // console.log('ze', errors, k)
      if (errors && errors[k]) {
        errors = errors[k];
      }else {
        return defaultClasses;
      }
    }
    return defaultClasses+" is-invalid"
  }


  return defaultClasses;
}

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  // const className = [props.className, getFieldCSSClasses(touched[field.name], errors[field.name])].join(" ") || "";
  // delete props.className;
  // if(field.name.includes('attribute_ar'))
  // {
  //   console.log("-------------------------------------");
  //   console.log(errors, touched);
  //   console.log(field.name);
  //   console.log(getFieldCSSClasses(touched[field.name], errors[field.name]));
  //   console.log(getFieldCSSClasses2(errors, touched,  field.name));
  // }


  return (
    <>
      {label && <label>{label}</label>}
      <input
        type={type}
        className={ getFieldCSSClasses2(errors, touched,  field.name) }
        {...field}
        {...props}
      />
      {withFeedbackLabel && (/*todo improve*/
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
