import {api_url, languages, upload_path} from "../../_AE/Helpers";
import * as Yup from "yup";

export const CONTEXT = "automates";
export const API_URI = api_url+"/"+CONTEXT;
export const UPLOAD_PATH = upload_path+"/"+CONTEXT
export const SEARCHABLE = ["titre","api_url",/*"api_port",*/"api_key"];
export const ROUTE_PREFIX = "/"+CONTEXT;
export const CONTEXT_TITLE = {
  singular: "Webserver",
  plural: "Webservers",
}
/**/


export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    // enabled: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "created_at",
  pageNumber: 1,
  pageSize: 10
};

export const defaultEntity = {
  titre: "",
  api_url: "https://",
  ip: "",
  // api_port: "8080",
  api_key: "",
  login: "",
  password: "",
}


