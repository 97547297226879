import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {requestPassword, resetPassword} from "../_redux/authCrud";

const initialValues = {
  _password: "",
  _password_confirm: "",
};

function ResetPassword(props) {
  const { intl, match: { params: { reset_token } } } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    _password: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD",})),
    _password_confirm: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("_password", {
        is: (val) => (!!(val && val.length > 0)),
        then: Yup.string().oneOf(
          [Yup.ref("_password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      resetPassword(reset_token, values._password)
        .then(() => {
          setIsRequested(true)
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            <div className="text-muted font-weight-bold">
              Enter new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {
              [
                {name:"_password",title:"New Password", type:"password"},
                {name:"_password_confirm",title:"Confirm Password", type:"password"},
              ].map(r=>(
                <div key={r.name} className="form-group fv-plugins-icon-container">
                  <input
                    placeholder={r.title}
                    type={r.type ? r.type :"text"}
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(r.name )}`}
                    name={r.name}
                    {...formik.getFieldProps(r.name)}
                  />
                  {formik.touched[r.name] && formik.errors[r.name] && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors[r.name]}</div>
                    </div>
                  )}
                </div>
              ))
            }
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Annuler
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
