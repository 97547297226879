import axios from "axios";
import {api_url} from "../../_AE/Helpers";

export const URL = api_url+"/admin/auth";
export const LOGIN_URL = URL+"/login";
export const REGISTER_URL = URL+"/register";
export const REQUEST_PASSWORD_URL = URL+"/forgot-password";

export const ME_URL = URL+"/me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(entity) {//todo _file and object
  return axios.post(REGISTER_URL, entity);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(reset_token, password) {
  return axios.post(`${URL}/reset-password/${reset_token}`, {password});
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

