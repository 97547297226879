import React, { useState } from "react";
import {ErrorMessage, Field, FieldArray, useFormik} from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Input} from "../../../_metronic/_partials/controls";

const initialValues = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",

  password: "",
  _password_confirm: "",
  _acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD", })),
    last_name: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD", })),
    username: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD", })),
    email: Yup.string().email("Wrong email format").min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required( intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD", }) ),
    password: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD",})),
    _password_confirm: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    _acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      register(values)
        .then(r => {
          props.history.push(`/`);
          // props.register(r.data._data.auth_token);
          disableLoading();
        })
        .catch(() => {
          setSubmitting(false);
          setStatus( intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN", }) );
          disableLoading();
        });
    },
  });


  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          vos coordonnées pour créer votre compte
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}


        {
          [
            {name:"first_name",title:"Prénom"},
            {name:"last_name",title:"Nom"},
            // {name:"username",title:"Username"},
            {name:"email",title:"Email"},
            {name:"password",title:"Mot de pass", type:"password"},
            {name:"_password_confirm",title:"Confirmer le Mot de passe", type:"password"},
          ].map(r=>(

            <div key={r.name} className="form-group fv-plugins-icon-container">
              {/*{console.log(r, formik)}*/}
              <input
                placeholder={r.title}
                type={r.type ? r.type :"text"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(r.name )}`}
                name={r.name}
                {...formik.getFieldProps(r.name)}
              />
              {formik.touched[r.name] && formik.errors[r.name] && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors[r.name]}</div>
                </div>
              )}
            </div>
          ))
        }


        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("_acceptTerms")}
            />
            <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
              J'accepte des conditions d'utilisation
            </Link>
            <span />
          </label>
          {formik.touched._acceptTerms && formik.errors._acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors._acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.values._acceptTerms}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Confirmer</span>
            {loading && <span className="ml-3 spinner spinner-white" />}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Annuler
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
