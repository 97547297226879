import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/auth/_redux/authRedux";
import {slice as usersSlice} from "../app/users/_redux/slice";
import {slice as composantsSlice} from "../app/composants/_redux/slice";
import {slice as automatesSlice} from "../app/automates/_redux/slice";

export const rootReducer = combineReducers({
  composants: composantsSlice.reducer,
  automates: automatesSlice.reducer,
  // users: usersSlice.reducer,
  auth: auth.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
