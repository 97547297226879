import {api_url, languages, upload_path} from "../../_AE/Helpers";
import * as Yup from "yup";

export const CONTEXT = "composants";
export const API_URI = api_url+"/"+CONTEXT;
export const UPLOAD_PATH = upload_path+"/"+CONTEXT
export const SEARCHABLE = ["libelle"];
export const ROUTE_PREFIX = "/"+CONTEXT;
export const CONTEXT_TITLE = {
  singular: "Pièce",
  plural: "Pièces et éléments",
}
/**/


export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    // enabled: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "created_at",
  pageNumber: 1,
  pageSize: 10
};

export const defaultEntity = {
  libelle: "",
  automate: "",
  objets: [
    {
      api_key_1: "",
      api_key_2: "",
      titre: "",
    }
  ]
}


