import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import * as USERS_CONFIG from "./users/UI/UIHelpers";
import * as C_CONFIG from "./composants/UI/UIHelpers";
import * as AM_CONFIG from "./automates/UI/UIHelpers";


const COMPOSANT = lazy(() => import("./composants/index"));
const AUTOMATES = lazy(() => import("./automates/index"));
const Users = lazy(() => import("./users/index"));


export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    <Redirect exact from="/" to="/composants"/>
                }
                <ContentRoute path={C_CONFIG.ROUTE_PREFIX} component={COMPOSANT}/>
                <ContentRoute path={AM_CONFIG.ROUTE_PREFIX} component={AUTOMATES}/>
                {/*<ContentRoute path={USERS_CONFIG.ROUTE.prefix} component={Users}/>*/}
            </Switch>
        </Suspense>
    );
}
