import {createSlice} from "@reduxjs/toolkit";
import {CONTEXT} from "../UI/UIHelpers";

export const callTypes = {
  list: "list",
  action: "action"
};

export const slice = createSlice({
  name: CONTEXT,
  initialState: {
    listLoading: false,
    actionsLoading: false,
    entities: [],
    datasource: [],
    entityForEdit: undefined,
    error: "nkklhj",
    lang:"en"
  },
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    manyFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    changeLang: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.lang = action.payload.lang;
    },
    fetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.entityForEdit;
      state.error = null;
    },
    init: (state, action) => {
      const { _data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.datasource = _data;
      state.entities = _data;
    },
    created: (state, action) => {
      const { _data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(_data);
      state.datasource.push(_data);
    },
    updated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload._data.id) {
          return action.payload._data;
        }
        return entity;
      });
    },
    deleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
      state.datasource = state.datasource.filter(el => el.id !== action.payload.id);
    },
    manyDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
